/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.ka-blue {
    color: rgba(27, 110, 194);
}

.traffic-light-red {
    color: #ec5146;
}

.traffic-light-yellow {
    color: #f5ca99;
}

.traffic-light-green {
    color: #00b36b;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
}

#content {
    padding-top: 74px;
}

@media (min-width: 1325px) {
    .widget-grid {
        --repeat: 3;
    }
}

.widget-grid {
    display: grid;
    grid-template-columns: repeat(var(--repeat, auto-fit), minmax(325px, 1fr));
    gap: 17px;
    padding: 10px;
}

@media (max-width: 1780px) {
    .widget {
        max-width: 475px;
    }
}

.widget {
    min-width: 325px; 
    min-height: 250px; 
    max-height: 250px;
}

.advanced-view {
    height: 250px;
}

.advanced-view .bi-box-arrow-in-down-right {
    transition: opacity 0.5s;
    opacity: 0;
}

.advanced-view:hover .bi-box-arrow-in-down-right {
    opacity: 1;
}

.crop-text-3 {
    -webkit-line-clamp: 3;
    max-height: 3lh;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.selected-option {
    position: relative;
}

.selected-option::after {
    position: absolute;
    top: 50%;
    right: .5rem;
    width: 1rem;
    height: 1rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 110 110' fill='%231b6ec2'%3e%3cpath d='M98.1,16.2c-2.5-2.3-6.4-2.2-8.7,0.2L36.7,70.1l-13-15.5c-2.9-3.5-7.9-4.1-11.1-1.4c-2.9,2.4-3.5,6.6-1.4,10.1l16.5,28c3.2,5.4,10.8,5.8,14.5,0.8l56.5-67.3C100.7,22.1,100.4,18.5,98.1,16.2z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 1rem 1rem;
    content: "";
    transform: translateY(-50%)
}

.chart-tooltip {
    transition: opacity 0.25s;
}
